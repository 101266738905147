<template>
  <v-menu :close-on-content-click="false" max-width="320" offset-x>
    <template v-slot:activator="{ on }">
      <FilterBottom v-on="on" />
    </template>
    <v-card class="pa-0">
      <v-container>
        <v-switch
          v-model="filters.status"
          true-value="S"
          false-value="N"
          :label="`${filters.status == 'S' ? 'Ativo' : 'Inativo'}`"
          @change="fetchTipoIndicador()"
        ></v-switch>

        <BaseFilter
          label="Tipo"
          service="sistemaService.tiposIndicadores"
          v-model="filters.tipo_indicador"
          item-text="tipo_indicador"
          item-value="tipo_indicador"
          prepend-inner-icon="mdi-finance"
          clearable
          chips
          single-line
          @change="fetchTipoIndicador()"
        />
      </v-container>
    </v-card>
  </v-menu>
</template>

<script>
import FilterBottom from "@/components/shared/bottons/FilterBottom";
import BaseFilter from "@/components/shared/BaseSelectService";
export default {
  name: "FiltroTipoIndicador",
  components: {
    FilterBottom,
    BaseFilter
  },

  data() {
    return {
      filters: { status: "S" },
      tiposIndicadores: {
        items: [],
        loading: false,
        selected: ""
      }
    };
  },
  methods: {
    fetchTipoIndicador() {
      this.$emit("selectedFilters", this.filters);
    }
  }
};
</script>
