<template>
  <v-dialog v-model="dialog" persistent max-width="30%">
    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar class="toolbar-color">
          <span class="headline ml-2">
            {{
              labelBtn != "Salvar" ? "Editar Indicador" : "Cadastrar Indicador"
            }}
          </span>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-col lg="4" md="12" sm="12" cols="12">
              <v-text-field
                clearable
                single-line
                label="Indicador"
                :rules="[rules.required]"
                v-model="formIndicador.id_tipo_indicador"
              >
              </v-text-field>
            </v-col>
            <v-col lg="4" md="12" sm="12" cols="12">
              <v-text-field
                clearable
                single-line
                label="Tipo"
                :rules="[rules.required]"
                v-model="formIndicador.tipo_indicador"
              >
              </v-text-field>
            </v-col>
            <v-col cols="6" sm="4" md="4">
              <v-autocomplete
                label="Status"
                :items="[
                  { text: 'Sim', value: 'S' },
                  { text: 'Não', value: 'N' }
                ]"
                :rules="[rules.required]"
                v-model="formIndicador.status"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="close">Fechar</v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="
            labelBtn != 'Salvar' ? editarIndicador() : cadastrarIndicador()
          "
          >{{ labelBtn }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import rules from "@/mixins/inputRules";
import sistema from "@/services/http/sistemaService";

export default {
  name: "ModalTipoIndicador",

  mixins: [rules],

  props: {
    dialog: {
      type: Boolean
    },

    labelBtn: {
      type: String
    },

    editIndicador: {
      type: Object
    }
  },

  data() {
    return {
      formIndicador: {},
      items: [
        {
          status: "Ativo",
          value: "S"
        },
        {
          status: "Inativo",
          value: "N"
        }
      ]
    };
  },

  watch: {
    editIndicador() {
      if (this.editIndicador) {
        this.formIndicador = this.editIndicador;
      }
    }
  },

  methods: {
    async cadastrarIndicador() {
      const validateStatus = this.$refs.form.validate();

      if (validateStatus) {
        await sistema()
          .tiposIndicadores()
          .store(this.formIndicador, {
            notification: true,
            message: "Indicador cadastrado com sucesso!"
          }),
          this.close();
      }
    },

    async editarIndicador() {
      const validateStatus = this.$refs.form.validate();
      if (validateStatus) {
        await sistema()
          .tiposIndicadores(this.formIndicador.id_tipo_indicador)
          .update(this.formIndicador, {
            notification: true,
            message: "Indicador editado com sucesso!"
          }),
          this.$notify({
            type: "success",
            text: "Indicador editado"
          });

        this.close();
      }
    },

    close() {
      this.$emit("close");
      this.$refs.form.reset();
    }
  }
};
</script>

<style></style>
