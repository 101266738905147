<template>
  <div>
    <BaseTable
      class="table-shadow"
      :headers="headers"
      :items="infoIndicador"
      :loading="loading"
      :paginate="true"
      :search="search"
      sort-by="tipo_indicador"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Lista de Tipo Indicador</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <RegisterBottom
            v-can-access="1622"
            @click="(dialog = true), (labelBtn = 'Salvar')"
          />

          <FiltroTipoIndicador
            @selectedFilters="fetchTipoIndicador((filters = $event))"
          />
        </v-toolbar>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <v-chip
          :color="formatStatus(item.status) === 'Sim' ? 'green' : 'red'"
          dark
        >
          {{ formatStatus(item.status) }}
        </v-chip>
      </template>
      <template v-slot:[`item.id_tipo_indicador`]="{ item }">
        {{ item.id_tipo_indicador | TitleCase }}
      </template>

      <template v-slot:[`item.tipo_indicador`]="{ item }">
        {{ item.tipo_indicador | TitleCase }}
      </template>

      <template v-slot:[`item.acoes`]="{ item }">
        <v-btn
          icon
          v-can-access="1624"
          @click="editItem(item)"
          color="orange"
          dark
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>

        <v-btn
          icon
          v-can-access="1625"
          @click="deleteItem(item)"
          class="ml-2"
          color="red"
          dark
          small
        >
          <v-icon>mdi-trash-can</v-icon>
        </v-btn>
      </template>
    </BaseTable>
    <ModalTipoIndicador
      :dialog="dialog"
      :labelBtn="labelBtn"
      :editIndicador="indicador"
      @close="dialog = false"
    />
  </div>
</template>

<script>
import FiltroTipoIndicador from "./FiltroTipoIndicador";
import BaseTable from "@/components/shared/NewBaseTable";
import sistema from "@/services/http/sistemaService";
import RegisterBottom from "@/components/shared/bottons/RegisterBottom";
import ModalTipoIndicador from "@/components/parametros/tipoIndicador/ModalTipoIndicador";

export default {
  name: "Tabela",

  components: {
    BaseTable,
    RegisterBottom,
    ModalTipoIndicador,
    FiltroTipoIndicador
  },

  data() {
    return {
      search: "",
      loading: false,
      filters: {},
      dialog: false,
      labelBtn: "Salvar",
      infoIndicador: [],
      status: "S",
      indicador: {},
      headers: [
        { text: "Id Tipo", value: "id_tipo_indicador" },
        { text: "Tipo", value: "tipo_indicador" },
        { text: "Status", value: "status", align: "center" },
        { text: "Ações", value: "acoes", align: "center" }
      ]
    };
  },

  methods: {
    async fetchTipoIndicador(filters) {
      this.loading = true;
      const { data } = await sistema()
        .tiposIndicadores()
        .show({
          per_page: -1,
          status: "S",
          ...filters
        });
      this.infoIndicador = data.data;
      this.loading = false;
    },

    editItem(item) {
      this.indicador = Object.assign({}, item);
      this.dialog = true;
      this.labelBtn = "Editar";
    },

    refresh() {
      this.dialog = false;
      this.fetchTipoIndicador(this.filters);
    },

    async deleteItem(item) {
      try {
        await this.$swal.confirm(
          "deletar Tipo indicador",
          `Essa ação não pode ser desfeita ${item.tipo_indicador}?`
        );

        sistema()
          .tiposIndicadores(item.id_tipo_indicador)
          .delete(
            {},
            {
              notification: "success",
              menssage: "Tipo Indicador deletado"
            }
          );
        this.refresh();
      } catch (error) {
        this.$notify({
          type: "error",
          text: "Erro ao deletar Tipo indicador"
        });
      }
    },

    formatStatus(item) {
      if (item == "S") item = "Sim";
      if (item == "N") item = "Não";
      return item;
    }
  },

  mounted() {
    this.fetchTipoIndicador();
  }
};
</script>
