var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BaseTable',{staticClass:"table-shadow",attrs:{"headers":_vm.headers,"items":_vm.infoIndicador,"loading":_vm.loading,"paginate":true,"search":_vm.search,"sort-by":"tipo_indicador"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Lista de Tipo Indicador")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('RegisterBottom',{directives:[{name:"can-access",rawName:"v-can-access",value:(1622),expression:"1622"}],on:{"click":function($event){(_vm.dialog = true), (_vm.labelBtn = 'Salvar')}}}),_c('FiltroTipoIndicador',{on:{"selectedFilters":function($event){_vm.fetchTipoIndicador((_vm.filters = $event))}}})],1)]},proxy:true},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.formatStatus(item.status) === 'Sim' ? 'green' : 'red',"dark":""}},[_vm._v(" "+_vm._s(_vm.formatStatus(item.status))+" ")])]}},{key:"item.id_tipo_indicador",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("TitleCase")(item.id_tipo_indicador))+" ")]}},{key:"item.tipo_indicador",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("TitleCase")(item.tipo_indicador))+" ")]}},{key:"item.acoes",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{directives:[{name:"can-access",rawName:"v-can-access",value:(1624),expression:"1624"}],attrs:{"icon":"","color":"orange","dark":""},on:{"click":function($event){return _vm.editItem(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{directives:[{name:"can-access",rawName:"v-can-access",value:(1625),expression:"1625"}],staticClass:"ml-2",attrs:{"icon":"","color":"red","dark":"","small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-icon',[_vm._v("mdi-trash-can")])],1)]}}],null,true)}),_c('ModalTipoIndicador',{attrs:{"dialog":_vm.dialog,"labelBtn":_vm.labelBtn,"editIndicador":_vm.indicador},on:{"close":function($event){_vm.dialog = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }